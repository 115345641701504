:root {
  --light-gray: #93b5b3;
  --gray: #bdbdbd;
  --dark-gray: #63707e;
  --dark-blue: #0f7a72;
  --light-blue: #c8dad3;
  --smoke: #f2f6f5;
}

body {
  background-color: var(--light-blue);
  color: var(--dark-gray);
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: var(--dark-blue);
  font-weight: bold;
  text-decoration: none;
  margin: 10px;
}

a:hover {
  text-decoration: underline;
}

button {
  align-items: center;
  background-color: var(--smoke);
  border-radius: 6px;
  border: none;
  color: var(--dark-gray);
  display: flex;
  justify-content: space-around;
  min-height: 30px;
  margin: 10px;
  padding: 5px;
}

hr {
  background: var(--smoke);
  border: 0px;
  height: 1px;
}

i {
  font-size: large;
  margin: 10px;
}

input {
  border-color: var(--dark-gray);
  border-radius: 6px;
  border-style: solid;
  color: var(--dark-gray);
}

input[type="checkbox"] {
  margin: 1em;
}

select {
  border-color: var(--dark-gray);
  border-radius: 6px;
  padding: 2px;
  margin: 5px;
}

textarea {
  border-radius: 6px;
  border: none;
  color: var(--dark-gray);
  font-family: inherit;
  padding: 5px;
  width: 95%;
}
